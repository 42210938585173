import { IDeviceItem, IDeviceList } from "../../../types/statsDevices";

export const checkDevice = (arr: IDeviceList[],  data: IDeviceItem) => {
    if (data.token !== 0) {
        return arr.map((device) => device.ident === data.token ? device.comment ? device.comment : device.name ? device.name : "Неопределенное устройство" : "");
    } else if (data.ipv4[0] !== "") {
        if (arr.map((device) => data.ipv4.includes(device.ident))) {
            let item = arr.filter((device) => data.ipv4.includes(device.ident) && device.device_type !== "")[0];

            if (item) {
                return item.comment !== "" ? item.comment : item.name;
            } else {
                return data.ipv4[0]
            }
        } else {
            return data.ipv4[0];
        }
    } else if (data.ipv6[0] !== "::") {
        return data.ipv6
    }
};

export const renderDeviceName = (list: IDeviceList[], data: IDeviceItem) => {
    const arr = checkDevice(list, data);

    return (
        Array.isArray(arr) ?
            arr.filter((el: any, ind: any) => arr.indexOf(el) === ind).length > 1 ?
                arr.filter((el: any, ind: any) => arr.indexOf(el) === ind) :
                "Удаленное устройство" :
            arr
    )
};
