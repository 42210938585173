export enum StatsDetailedActionsTypes {
    GET_STATS_DETAILED = "GET_STATS_DETAILED",
    GET_STATS_DETAILED_SUCCESS = "GET_STATS_DETAILED_SUCCESS",
    GET_STATS_DETAILED_FAILURE = "GET_STATS_DETAILED_FAILURE",
    GET_STATS_DETAILED_INFO = "GET_STATS_DETAILED_INFO",
    GET_STATS_DETAILED_INFO_SUCCESS = "GET_STATS_DETAILED_INFO_SUCCESS",
    GET_STATS_DETAILED_INFO_FAILURE = "GET_STATS_DETAILED_INFO_FAILURE",
    STATS_DETAILED_DATA = "STATS_DETAILED_DATA",
    STATS_DETAILED_REQUEST = "STATS_DETAILED_REQUEST"
};

export interface IDetailedItem {
    detailed: string,
    requests: number
    blocks: number,
    cat_ids: string[],
};

export interface IDomainInfo {
    domain: string,
    status: string
};

export interface IInitialState {
    statsDetailed: string,
    statsDetailedData: IDetailedItem[],
    loading: boolean,
    error: null | string,
    getDetailedInfo: IDomainInfo,
    getInfoLoading: boolean,
    getInfoError: null | string,
    request: any
};

interface IGetStatsDetailed {
    type: StatsDetailedActionsTypes.GET_STATS_DETAILED,
};

interface IGetStatsDetailedSuccess {
    type: StatsDetailedActionsTypes.GET_STATS_DETAILED_SUCCESS,
    payload: string
};

interface IGetStatsDetailedFailure {
    type: StatsDetailedActionsTypes.GET_STATS_DETAILED_FAILURE,
    payload: string
};

interface IGetStatsDetailedInfo {
    type: StatsDetailedActionsTypes.GET_STATS_DETAILED_INFO,
};

interface IGetStatsDetailedInfoSuccess {
    type: StatsDetailedActionsTypes.GET_STATS_DETAILED_INFO_SUCCESS,
    payload: IDomainInfo
};

interface IGetStatsDetailedInfoFailure {
    type: StatsDetailedActionsTypes.GET_STATS_DETAILED_INFO_FAILURE,
    payload: string
};

interface IStatsDetailedData {
    type: StatsDetailedActionsTypes.STATS_DETAILED_DATA,
    payload: IDetailedItem[]
};

interface IStatsDetailedRequest {
    type: StatsDetailedActionsTypes.STATS_DETAILED_REQUEST,
    payload: any
};

export type StatsDetailedActions =
    IGetStatsDetailed |
    IGetStatsDetailedSuccess |
    IGetStatsDetailedFailure |
    IGetStatsDetailedInfo |
    IGetStatsDetailedInfoSuccess |
    IGetStatsDetailedInfoFailure |
    IStatsDetailedData |
    IStatsDetailedRequest;
