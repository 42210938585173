import React, { FC } from "react";

const TopFactsInfo: FC = () => {
    return (
        <div className="stats-top-facts-info">
            <div className="stats-top-facts-info-item">
                <div>
                    <h5 className="stats-top-facts-info-item-title">Топ заблокированных доменов</h5>
                    <p>Список из 10 доменов, к которым ваши пользователи постоянно пытались получить доступ, попадающих в заблокированные категории или точечно заблокированных вами.</p>
                </div>
            </div>
            <div className="stats-top-facts-info-item">
                <div>
                    <h5 className="stats-top-facts-info-item-title">Топ заблокированных устройств</h5>
                    <p>Список из 10 устройств, которые чаще всего пытались получить доступ к заблокированным вами доменам и категориям.</p>
                </div>
            </div>
            <div className="stats-top-facts-info-item">
                <div>
                    <h5 className="stats-top-facts-info-item-title">Топ заблокированных категорий</h5>
                    <p>Список из 10 категорий, к которым ваши пользователи постоянно пытались получить доступ, подпадающих под настройки блокировки вашего аккаунта.</p>
                </div>
            </div>
        </div>
    )
};

export default TopFactsInfo;
