import { FC, useContext } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import ModalContext from "../../../contexts/modalContext";
import Modal from "../../UI/modal/Modal";
import Button from "../../UI/button/Button";
import '../DataExport.scss';

const AmazonS3DataExportComplete: FC = () => {
    const navigate = useNavigate();

    const { isShowS3IntegrationCompleteModal, setIsShowS3IntegrationCompleteModal } = useContext(ModalContext);
    const s3IntegrationCreate = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationCreateData);

    const completed = () => {
        setIsShowS3IntegrationCompleteModal(!isShowS3IntegrationCompleteModal);

        if (s3IntegrationCreate){
            return navigate("/dashboard/new_statistics/data-export/");
        }
    };

    return (
        <Modal
            title=""
            width="384"
            content={
                <div className="data-export-notice">
                    <div className="data-export-notice-icon-complete"></div>
                    <p className="data-export-notice-message">Успешно завершено.</p>
                    <div className="data-export-notice-controls">
                        <Button
                            type="button"
                            name="Понятно"
                            modifier="primary"
                            width="336px"
                            clickHeandler={completed}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default AmazonS3DataExportComplete;
