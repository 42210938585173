import React, { FC, useState, useEffect, useContext } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IReportRequestData } from "../../types/statsMonthlyReport";
import { createRecipientsList, updateRecipientsList } from "../../api";
import { addDays } from 'date-fns';
import DatePicker from "react-datepicker";
import ModalContext from "../../contexts/modalContext";
import PdfReportContext from "../../contexts/pdfReportContext";
import Toggler from "../UI/toggler/Toggler";
import DropDownMenu from "../UI/dropdown-menu/DropDownMenu";
import Button from "../UI/button/Button";
import './Controls.scss';

const Report: FC = () => {

    const { isShowRecipientsModal, setIsShowRecipientsModal } = useContext(ModalContext);
    const { isShowReportPreview, setIsShowReportPreview } = useContext(PdfReportContext);

    const highlightsDataResult = useTypedSelector(state => state.statsHighlights.dataResult);
    const categoriesDataResult = useTypedSelector(state => state.statsCategories.dataResult);
    const lastMonthDataResult = useTypedSelector(state => state.statsCategories.lastMontnDataResult);

    const {
        fetchStatsExport,
        fetchReportData,
        fetchRecipientsList,
        notification
    } = useActions();

    const isAuthData = useTypedSelector(state => state.auth.authData);
    const permissions = useTypedSelector(state => state.auth.permissionsByFeatures);
    const adminsError = useTypedSelector(state => state.admins.error);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const checkRecipientsList = useTypedSelector(state => state.statsMonthlyReport.checkRecipientsList)[0];
    const loading = useTypedSelector(state => state.statsMonthlyReport.loading);

    const [isShowExportMenu, setIsShowExportMenu] = useState(false);
    const [isShowReportSettingsMenu, setIsShowReportSettingsMenu] = useState(false);

    const [isMinuteStatisticsIsRange, setIsMinuteStatisticsIsRange] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(new Date());
    const [isSelectedDate, setIsSelectedDate] = useState(false);

    //monthly report data
    const [isMonthlyReport, setIsMonthlyReport] = useState(false);

    const [reportSettings, setReportSettings] = useState([
        {
            id: "get-d-report",
            name: 'Получить дневной отчет',
            description: "CSV",
            isChecked: true,
            period: "day",
            disabled: false
        },
        {
            id: "get-w-report",
            name: 'Получить недельный отчет',
            description: "CSV",
            isChecked: false,
            period: "week",
            disabled: false
        },
        {
            id: "get-m-report",
            name: 'Получить месячный отчет',
            description: "CSV",
            isChecked: false,
            period: "month",
            disabled: false
        }
    ]);

    const monthlyReportData: IReportRequestData = {
        recipients: [],
        file_types: ["csv"],
        period: checkRecipientsList && checkRecipientsList.period,
        is_active: isMonthlyReport
    };

    const onCheckedReportPeriod = (e: any): void => {
        fetchRecipientsList(isAuthData.user_id, updateRecipientsList, { ...monthlyReportData, period: e.target.value });
    };

    //export stats action

    const onExportReport = (data: string): void => {
        setIsShowExportMenu(!isShowExportMenu);

        if (data === 'pdf') {
            setIsShowReportPreview(!isShowReportPreview);
        }

        if (data === 'csv') {
            setIsMinuteStatisticsIsRange(!isMinuteStatisticsIsRange);
        }
    };

    const onChangeMinuteStatistics = (dates: any, event: any) => {
        const [start, end] = dates;

        setIsSelectedDate(event.target.ariaSelected);
        setStartDate(start);
        setEndDate(end);
        setIsShowReportSettingsMenu(!isShowReportSettingsMenu);
    };

    const fetchMinuteStatisticsExport = () => {
        fetchStatsExport(isAuthData.user_id, {
            ...requestData,
            start: startDate && startDate.toISOString().split('T')[0],
            end: endDate ? endDate.toISOString().split('T')[0] : startDate.toISOString().split('T')[0],
            file_types: ["csv"],
            period: "range"
        });
        setIsMinuteStatisticsIsRange(!isMinuteStatisticsIsRange);

        setStartDate(new Date());
        setEndDate(new Date());
    };

    const closeMinuteStatisticsExport = () => {
        setIsMinuteStatisticsIsRange(!isMinuteStatisticsIsRange);

        setStartDate(new Date());
        setEndDate(new Date());
    };

    //monthly report action
    const onActiveMonthlyReport = () => {
        setIsMonthlyReport(!isMonthlyReport);
        fetchReportData({ ...monthlyReportData, is_active: !isMonthlyReport });

        if (!checkRecipientsList) {
            fetchRecipientsList(isAuthData.user_id, createRecipientsList, { ...monthlyReportData, is_active: !isMonthlyReport });

            notification({
                type: 'success',
                message: "monthly report create",
                isNotification: true
            })
        } else {
            fetchRecipientsList(isAuthData.user_id, updateRecipientsList, { ...monthlyReportData, is_active: !isMonthlyReport });

            notification({
                type: 'success',
                message: `${checkRecipientsList.is_active ?
                    "Автоматическая доставка периодических отчетов успешно отключена. Периодическая статистика больше не будет доставляться на ваш почтовый ящик." :
                    "Автоматическая доставка периодических отчетов успешно настроена. С этого момента на ваш почтовый ящик будет приходить периодическая статистика."}`,
                isNotification: true
            })
        }
    };

    useEffect((): void => {
        if (isShowRecipientsModal) {
            setIsShowReportSettingsMenu(!isShowReportSettingsMenu);
        }
    }, [isShowRecipientsModal]);

    useEffect((): void => {
        const isFirstRender = !checkRecipientsList;

        if (!isFirstRender) {
            setIsMonthlyReport(checkRecipientsList ? checkRecipientsList.is_active : false);
        }
    }, [checkRecipientsList]);

    useEffect((): void => {
        if (isSelectedDate) {
            setStartDate(new Date());
            setEndDate(new Date());
        }
    }, [isSelectedDate]);

    useEffect(() => {
        if (permissions && checkRecipientsList) {
            setReportSettings([
                {
                    id: "get-d-report",
                    name: 'Получить дневной отчет',
                    description: "CSV",
                    isChecked: checkRecipientsList.period === 'day' ? true : false,
                    period: "day",
                    disabled: permissions.max_detailed_stat_export_period >= 1 ? false : true
                },
                {
                    id: "get-w-report",
                    name: 'Получить недельный отчет',
                    description: "CSV",
                    isChecked: checkRecipientsList.period === 'week' ? true : false,
                    period: "week",
                    disabled: permissions.max_detailed_stat_export_period >= 7 ? false : true
                },
                {
                    id: "get-m-report",
                    name: 'Получить месячный отчет',
                    description: "CSV",
                    isChecked: checkRecipientsList.period === 'month' ? true : false,
                    period: "month",
                    disabled: permissions.max_detailed_stat_export_period >= 30 ? false : true
                }
            ])
        }
    }, [checkRecipientsList]);

    const exportList = [
        {
            id: "export-csv",
            name: 'Экспорт CSV',
            description: "Подробная таблица отчета",
            format: "csv",
            disabled: false
        },
        {
            id: "export-json",
            name: 'Экспорт JSON',
            description: "Подробная таблица отчета",
            format: "json",
            disabled: true
        },
        {
            id: "export-pdf",
            name: 'Экспорт PDF',
            description: "Графики",
            format: "pdf",
            disabled: highlightsDataResult !== null && categoriesDataResult !== null && lastMonthDataResult !== null ? false : true
        }
    ];

    return (
        <div className="controls-report">
            {permissions && permissions.max_detailed_stat_export_period !== 0 ?
                <DropDownMenu
                    name="Экспорт"
                    textColor="white"
                    closeTrigger={isShowExportMenu}
                    content={
                        exportList.map((item, index) => (
                            <button
                                key={index}
                                id={item.id}
                                type="submit"
                                onClick={()=> onExportReport(item.format)}
                                className="dropdown-menu-list-item "
                                disabled={item.disabled}
                            >
                                {item.name}
                                <span>{item.description}</span>
                            </button>
                        ))
                    }
                /> : null
            }

            {isMinuteStatisticsIsRange &&
                <div className="controls-report-datepicker">
                    <DatePicker
                        selected={startDate}
                        onChange={(date, event) => onChangeMinuteStatistics(date, event)}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                        placeholderText="Select end date"
                        className="controls-report-datepicker-end"
                        icon="controls-report-datepicker-icon"
                        maxDate={isSelectedDate ?
                            addDays(startDate, permissions ? permissions.max_detailed_stat_export_period : 0) : null
                        }
                    />

                    <div className="controls-report-datepicker-buttons">
                        <Button
                            type="button"
                            name="Закрыть"
                            modifier="secondary"
                            width="84px"
                            clickHeandler={closeMinuteStatisticsExport}
                        />
                        <Button
                            type="submit"
                            name="Экспорт"
                            modifier="primary"
                            width="84px"
                            clickHeandler={fetchMinuteStatisticsExport}
                        />
                    </div>
                </div>
            }

            {permissions && permissions.max_detailed_stat_export_period !== 0 ?
                <DropDownMenu
                    name="Настроить периодический отчет"
                    closeTrigger={isShowRecipientsModal}
                    variant="secondary"
                    toggler={
                        <Toggler
                            id={'get-monthly-report'}
                            name={'get-monthly-report'}
                            changeHeandler={onActiveMonthlyReport}
                            checked={isMonthlyReport}
                            isDisabled={loading ? true : false}
                        />
                    }
                    content={
                        <>
                            {reportSettings.map((item, index) => (
                                <label key={index} htmlFor={item.id} className="controls-report-periodic">
                                    <div className="controls-report-periodic-item">
                                        <input
                                            type="radio"
                                            id={item.id}
                                            name="get-report"
                                            value={item.period}
                                            onClick={(e)=> onCheckedReportPeriod(e)}
                                            defaultChecked={item.isChecked}
                                            disabled={item.disabled}
                                        />
                                        <p>{item.name}</p>
                                    </div>
                                    <span>{item.description}</span>
                                </label>
                            ))}

                            {!adminsError &&
                                <>
                                    <hr />
                                    <button
                                        type="button"
                                        className="controls-report-assign-button"
                                        onClick={()=> setIsShowRecipientsModal(!isShowRecipientsModal)}
                                    >
                                        Назначить получателей
                                    </button>
                                </>
                            }
                        </>
                    }
                /> : null
            }
        </div>
    )
};

export default Report;
