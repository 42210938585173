import React, { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IRequestData } from "../../types/statsRequestData";
import { IDeviceList } from "../../types/statsDevices";
import { useCheckBreakpoint } from "../../hooks/useCheckBreakpoint";
import Navigation from "./Navigation";
import DatePicker from "react-datepicker";
import Report from "./Report";
import "react-datepicker/dist/react-datepicker.css";
import '../UI/select/Select.scss';
import './Controls.scss';

const Controls: FC = () => {
    const location = useLocation();
    const { allProfileIds, selectedDevice, statsPeriod, statsRequestData, deviceList } = useActions();
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const allProfiles = useTypedSelector(state => state.profiles.allProfilesIds);
    const getHighlightsStats = useTypedSelector(state => state.statsHighlights.request);
    const getActivityStats = useTypedSelector(state => state.statsActivity.request);
    const getCategoriesStats = useTypedSelector(state => state.statsCategories.request);
    const getDomainsStats = useTypedSelector(state => state.statsDomains.request);
    const getDevicesStats = useTypedSelector(state => state.statsDevices.request);
    const getDetailed = useTypedSelector(state => state.statsDetailed.request)
    const getNetworks = useTypedSelector(state => state.networks.networks);
    const getAgentInfo = useTypedSelector(state => state.agentInfo.agentInfo);
    const getAdUsers = useTypedSelector(state => state.adUsers.adUsers);

    const [policy, setPolicy] = useState({
        id: "",
        name: "Все"
    });
    const [period, setPeriod] = useState({
        id: "id_today",
        name: "Today"
    });
    const [devicesData, setDevicesData] = useState<IDeviceList[]>([]);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [periodIsRange, setPeriodIsRange] = useState<boolean>(false);
    const [requestData, setRequestData] = useState<IRequestData>({
        tokens: [],
        period: period.name.toLocaleLowerCase(),
        profile_ids: [],
        start: new Date().toISOString().split('T')[0],
        end: new Date().toISOString().split('T')[0],
        group_by: "hour",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        limit: 1000,
        domains: [],
        ipv4_addresses: [],
        ipv6_networks: [],
        file_types: [],
        cache_ttl: 180
    });

    // get devices list
    let devicesList: IDeviceList[] = [{
        id: null,
        name: "Все",
        ident: "",
        device_type: "",
        field_type: "all",
        comment: ""
    }];

    useEffect((): void => {
        getNetworks.results.map(network => {
            switch (network.type) {
                case "static4":
                    devicesList.push({
                        id: network.id,
                        name: network.ipv4,
                        ident: network.ipv4,
                        device_type: "network",
                        field_type: "ipv4_addresses",
                        comment: network.comment
                    });
                    break;
                case "static6":
                    devicesList.push({
                        id: network.id,
                        name: network.ipv6,
                        ident: network.ipv6,
                        device_type: "network",
                        field_type: "ipv6_networks",
                        comment: network.comment
                    });
                    break;
                case "dyndns":
                    devicesList.push({
                        id: network.id,
                        name: network.hostname,
                        ident: network.ipv4 ? network.ipv4 : network.ipv6 ? network.ipv6 : network.hostname,
                        device_type: "network",
                        field_type: network.ipv4 ? "ipv4_addresses" : network.ipv6 ? "ipv6_networks" : "tokens",
                        comment: network.comment
                    });
                    break;
                case "vpn":
                    devicesList.push({
                        id: network.id,
                        name: network.hostname,
                        ident: network.ipv4 ? network.ipv4 : network.ipv6 ? network.ipv6 : network.hostname,
                        device_type: "network",
                        field_type: network.ipv4 ? "ipv4_addresses" : network.ipv6 ? "ipv6_networks" : "tokens",
                        comment: network.comment
                    });
                    break;
                default:
                    break;
            }
        });

        getAgentInfo.results.map(agentInfo => {
            devicesList.push({
                id: agentInfo.id,
                name: agentInfo.hostname,
                ident: agentInfo.token,
                device_type: "agent_info",
                field_type: "tokens",
                comment: agentInfo.comment
            });
        });

        getAdUsers.results.map(adUser => {
            devicesList.push({
                id: adUser.id,
                name: adUser.name,
                ident: adUser.token,
                device_type: "ad_users",
                field_type: "tokens",
                comment: adUser.comment
            })
        });

        setDevicesData(devicesList);

        //dispatch device list
        deviceList(devicesList);
    }, [getNetworks, getAgentInfo, getAdUsers]);

    //get profiles ids

    useEffect(() => {
        let defaultProfiles: number[] = [];

        profiles.map(item => {
            defaultProfiles.push(item.id)
        })

        allProfileIds(defaultProfiles);
        setRequestData({ ...requestData, profile_ids: defaultProfiles })
    }, [profiles]);

    //show/hide range field
    useEffect((): void => {
        statsPeriod(period.name)

        if (period.name === 'Range') {
            setPeriodIsRange(true);
        }

        if (period.name !== 'Range') {
            setPeriodIsRange(false);
        }
    }, [period]);

    //statistics update
    useEffect((): void => {
        statsRequestData(requestData);
    }, [requestData, startDate, endDate]);

    const policyHandler = (e: React.ChangeEvent<HTMLSelectElement>):void => {
        setPolicy({
            ...policy,
            id: e.target.selectedOptions[0].id,
            name: e.target.value
        });

        if (e.target.value === "Все") {
            setRequestData({
                ...requestData,
                profile_ids: allProfiles,
            });
        } else {
            setRequestData({
                ...requestData,
                profile_ids: [+e.target.selectedOptions[0].id],
            });
        }
    };

    const periodHandler = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setPeriod({
            ...period,
            id: e.target.selectedOptions[0].id,
            name: e.target.value
        });
        setRequestData({
            ...requestData,
            period: e.target.value.toLocaleLowerCase(),
            group_by: e.target.value !== 'Today' && e.target.value !== 'Yesterday' ? 'day' : 'hour',
            cache_ttl:
                e.target.value === 'Today' ? 180 :
                e.target.value === 'Yesterday' ? 3600 :
                e.target.value === 'Week' ? 3600 :
                e.target.value === 'Month' ? 3600 :
                e.target.value === 'Range' ? 3600 :
                3600
        });
    };

    const devicesHandler = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        let value = JSON.parse(e.target.value);

        switch (value.data_type) {
            case "all":
                setRequestData({ ...requestData, ipv4_addresses: [], ipv6_networks: [], tokens: [] })
                break;
            case "ipv4_addresses":
                setRequestData({ ...requestData, ipv4_addresses: [value.ident], ipv6_networks: [], tokens: [] })
                break;
            case "ipv6_networks":
                setRequestData({ ...requestData, ipv6_networks: [value.ident], ipv4_addresses: [], tokens: [] })
                break;
            case "tokens":
                setRequestData({ ...requestData, tokens: [value.ident], ipv4_addresses: [], ipv6_networks: [] })
                break;
            default:
                break;
        }
        selectedDevice(JSON.parse(e.target.value));
    };

    const startDateHandler = (date:any): void => {
        setStartDate(date);

        setRequestData({
            ...requestData,
            start: date.toISOString().split('T')[0]
        });
    };

    const endDateHandler = (date:any): void => {
        setEndDate(date);

        setRequestData({
            ...requestData,
            end: date.toISOString().split('T')[0]
        });
    };

    const getStatsToRange = () => {
        if (location.pathname.includes('detailed')) {
            getDetailed();
        } else {
            getHighlightsStats();
            getActivityStats();
            getCategoriesStats();
            getDomainsStats();
            getDevicesStats();
        }
    };

    const periodData = [
        {
            id: 'id_today',
            name: 'Сегодня',
            period: 'Today'
        },
        {
            id: 'id_yesterday',
            name: 'Вчера',
            period: 'Yesterday'
        },
        {
            id: 'id_week',
            name: 'Неделя',
            period: 'Week',
        },
        {
            id: 'id_month',
            name: 'Месяц',
            period: 'Month'
        },
        {
            id: 'id_range',
            name: 'Период',
            period: 'Range'
        }
    ];

    const renderSelectPeriod = () => {
        return (
            <>
                <div className="select">
                    <select
                        value={period.name}
                        onChange={e => periodHandler(e)}
                        style={{ width: "136px" }}
                    >
                        <option>Все</option>
                        {periodData && periodData.map((option: any) => (
                            <option key={option.id} id={option.id} value={option.period}>{option.name}</option>
                        ))}
                    </select>
                </div>

                {periodIsRange &&
                    <>
                        <div className="controls-period-datepicker">
                            <DatePicker
                                showIcon
                                selected={startDate}
                                onChange={(date) => startDateHandler(date)}
                                placeholderText="Select start date"
                                className="controls-period-datepicker-start"
                                icon="controls-period-datepicker-icon"
                            />
                            <div style={{ width: '8px' }}></div>
                            <DatePicker
                                showIcon
                                selected={endDate}
                                onChange={(date) => endDateHandler(date)}
                                placeholderText="Select end date"
                                className="controls-period-datepicker-end"
                                icon="controls-period-datepicker-icon"
                            />
                        </div>
                        <button
                            type="button"
                            name="Submit"
                            className="controls-period-datepicker-submit"
                            onClick={getStatsToRange}
                        />
                    </>
                }
            </>
        )
    };

    return (
        <div className="controls">
            <div className="controls-wrapper">
                <Navigation />
            </div>

            <div className="controls-wrapper">
                <div className="controls-settings">

                    <div className="controls-settings-elements-period">
                        {useCheckBreakpoint(576) ? null : renderSelectPeriod()}
                    </div>

                    <div className="controls-settings-elements">
                        <div className="controls-settings-elements-select">
                            <div className={`select ${useCheckBreakpoint(576) && "flex-column"}`}>
                                <label>Выберите профиль</label>
                                <select
                                    value={policy.name}
                                    onChange={e => policyHandler(e)}
                                    style={{ width: useCheckBreakpoint(576) ? "100%" : "226px" }}
                                >
                                    <option>Все</option>
                                    {profiles && profiles.map((option: any) => (
                                        <option key={option.id} id={option.id}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className={`select ${useCheckBreakpoint(576) && "flex-column"}`}>
                                <label>Выберите устройство</label>
                                <select
                                    onChange={e => devicesHandler(e)}
                                    style={{ width: useCheckBreakpoint(576) ? "100%" : "226px" }}
                                >
                                    {devicesData.map((option: IDeviceList, index: number) => (
                                        option.ident !== null &&
                                        <option
                                            key={index}
                                            value={JSON.stringify({ ident: option.ident, data_type: option.field_type })}
                                        >
                                            {option.comment ? option.comment : option.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <Report />
                    </div>
                </div>

                {/* {useCheckBreakpoint(576) &&
                    <div className="flex-column">
                        {renderSelectPeriod()}
                    </div>
                } */}
            </div>
        </div>
    )
};

export default Controls;
