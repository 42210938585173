import { FC, useContext } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { NavLink, useLocation } from "react-router-dom";
import { useCheckBreakpoint } from "../../hooks/useCheckBreakpoint";
import AuthContext from "../../contexts/authContext";
import NavbarContext from "../../contexts/navbarContext";
import './Menu.scss';

const Menu: FC = () => {
    const location = useLocation();
    const { navbar } = useContext(AuthContext);
    const { isShowNavbar } = useContext(NavbarContext);

    const isAuthData = useTypedSelector(state => state.auth.authData);

    const renderMenu = () => {
        return (
            <>
                {navbar.map((item: any, index: any) => (
                    <NavLink
                        key={index}
                        to={`${process.env.REACT_APP_BASE_URL}${item.url}`}
                        id={index}
                        className={`${location.pathname.includes(item.url) ? "nav-item-active" : "nav-item"} nav-icon-${item.css_class}` }
                        state={item.title}
                        >
                        {item.title}
                    </NavLink>
                ))}
            </>
        )
    };

    return (
        <>
            {useCheckBreakpoint(768) ?
                <>
                    {isShowNavbar &&
                        <div className="nav-mobile">
                            {renderMenu()}
                        </div>
                    }
                </> :
                <div className="nav">
                    <a href={process.env.REACT_APP_BASE_URL} className="nav-logo">
                        <div className="nav-logo-icon"></div>
                    </a>
                    <div className="nav-info">
                        <p className="nav-info-username">{isAuthData.username}</p>
                        <span className="nav-info-contract">{`Договор ${'XXX'}`}</span>
                    </div>
                    {renderMenu()}
                </div>
            }
        </>
    );
};

export default Menu;
