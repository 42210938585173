import { FC } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ICategotiesItemStats } from '../../../types/statsCategories';
import { renderDeviceName } from '../../stats/devices/DeviceItemName';
import TopFactsInfo from '../../stats/top-facts/TopFactsInfo';
import ProgressLoader from '../../UI/progress-loader/ProgressLoader';
import '../../stats/top-facts/TopFacts.scss';
import '../PdfReport.scss';

const PageTwo: FC = () => {
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const domainsData = useTypedSelector(state => state.statsDomains.statsDomainsData)
        .filter((item) => item.blocks !== 0);
    const domainsLoading = useTypedSelector(state => state.statsDomains.loading);
    const domainsError = useTypedSelector(state => state.statsDomains.error);
    const devicesData = useTypedSelector(state => state.statsDevices.statsDevicesData);
    const deviceList = useTypedSelector(state => state.statsDevices.deviceList);
    const devicesLoading = useTypedSelector(state => state.statsDevices.loading);
    const devicesError = useTypedSelector(state => state.statsDevices.error);
    const categoriesData = useTypedSelector(state => state.statsCategories.statsCategoriesData)
        .sort((a,b) => b.blocks - a.blocks)
        .filter((item) => item.blocks !== 0)
        .slice(0, 10);
    const categoriesLoading = useTypedSelector(state => state.statsCategories.loading);
    const categoriesError = useTypedSelector(state => state.statsCategories.error);

    return (
        <div className="stats-top-facts-report">
            <h2 className="stats-top-facts-report-title">Обзор заблокированных активностей</h2>

            <div className="stats-top-facts-report-data">
                <div className="stats-top-facts-domains">
                    <div
                        style={{
                            opacity: domainsLoading || domainsError ? 0.2 : 1,
                            pointerEvents: domainsLoading || domainsError ? 'none' : 'auto'
                        }}
                    >
                        <h3 className="stats-top-facts-domains-list-title">ТОП ЗАБЛОКИРОВАННЫХ ДОМЕНОВ</h3>
                        {domainsLoading &&
                            <ProgressLoader />
                        }
                        <ul className="stats-top-facts-domains-list">
                            {domainsData.map((item, index) => (
                                item.domain &&
                                <li
                                    key={index}
                                    className="stats-top-facts-domains-list-item"
                                >
                                    <p className="stats-top-facts-domains-list-item-name">{item.domain}</p>
                                    <p className="stats-top-facts-domains-list-item-requests">{item.blocks}</p>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {domainsError &&
                        <div className="stats-top-facts-domains-error">
                            <p>Упс! Что-то пошло не так... Попробуйте позже.</p>
                        </div>
                    }

                    {!domainsError && domainsData.length === 0 &&
                        <div className="stats-top-facts-domains-error">
                            <p>У вас пока нет статистики</p>
                        </div>
                    }
                </div>

                {
                    requestData.ipv4_addresses.length === 0 &&
                    requestData.ipv6_networks.length === 0 &&
                    requestData.tokens.length === 0 ?
                    <div className="stats-top-facts-devices">
                        <div
                            style={{
                                opacity: devicesLoading || devicesError ? 0.2 : 1,
                                pointerEvents: devicesLoading || devicesError ? 'none' : 'auto'
                            }}
                        >
                            <h3 className="stats-top-facts-devices-list-title">ТОП ЗАБЛОКИРОВАННЫХ УСТРОЙСТВ</h3>
                            {devicesLoading &&
                                <ProgressLoader />
                            }
                            <ul className="stats-top-facts-devices-list">
                                {devicesData.map((item, index) => (
                                    item.requests &&
                                    <li
                                        key={index}
                                        className="stats-top-facts-devices-list-item"
                                    >

                                        <p className="stats-top-facts-devices-list-item-name">
                                            {renderDeviceName(deviceList, item)}
                                        </p>
                                        <p className="stats-top-facts-devices-list-item-requests">{item.blocks}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {devicesError &&
                            <div className="stats-top-facts-devices-error">
                                <p>Упс! Что-то пошло не так... Попробуйте позже.</p>
                            </div>
                        }

                        {!devicesError && devicesData.length === 0 &&
                            <div className="stats-top-facts-devices-error">
                                <p>У вас пока нет статистики</p>
                            </div>
                        }
                    </div> : null
                }

                <div className="stats-top-facts-categories">
                    <div
                        style={{
                            opacity: categoriesLoading || categoriesError ? 0.2 : 1,
                            pointerEvents: categoriesLoading || categoriesError ? 'none' : 'auto'
                        }}
                    >
                        <h3 className="stats-top-facts-categories-list-title">ТОП ЗАБЛОКИРОВАННЫХ КАТЕГОРИЙ</h3>
                        {categoriesLoading &&
                            <ProgressLoader />
                        }
                        <ul className="stats-top-facts-categories-list">
                            {categoriesData.map((item: ICategotiesItemStats, index: number) => (
                                item.cat &&
                                <li
                                    key={index}
                                    className="stats-top-facts-categories-list-item"
                                >
                                    <p className="stats-top-facts-categories-list-item-name">
                                        {item.cat && item.cat.title}
                                    </p>
                                    <p className="stats-top-facts-categories-list-item-requests">
                                        {item && item.blocks}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {categoriesError &&
                        <div className="stats-top-facts-categories-error">
                            <p>Упс! Что-то пошло не так... Попробуйте позже.</p>
                        </div>
                    }

                    {!categoriesError && categoriesData.length === 0 &&
                        <div className="stats-top-facts-categories-error">
                            <p>У вас пока нет статистики</p>
                        </div>
                    }
                </div>
            </div>

            <p className="stats-top-facts-report-info-title">Общие сведения о потоке ваших запросов и общее количество конкретных типов запросов.</p>

            <TopFactsInfo />
        </div>
    )
};

export default PageTwo;
