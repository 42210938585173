import { FC, useState } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ICategotiesItemStats } from '../../../types/statsCategories';
import usePagination from '../../../hooks/usePagination';
import useSortable from '../../../hooks/useSortable';
import '../../stats/domains/Domains.scss';
import '../PdfReport.scss';

const PageSix: FC = () => {
    const categories = useTypedSelector(state => state.statsCategories.statsCategoriesData);
    const domainsDataResult = useTypedSelector(state => state.statsDomains.dataResult);
    const error = useTypedSelector(state => state.statsDomains.error);

    const [filteredStatus, setFilteredStatus] = useState<string>("Blocked");
    const [filteredCategory, setFilteredCategory] = useState<{id: number, title: string}>({
        id: NaN,
        title: "All"
    });

    const formatter = new Intl.NumberFormat('en-US');

    //sorted domains to requests
    let { result, sortConfig } = useSortable(domainsDataResult);


    if (filteredCategory.title !== "All") {
        result = result.filter(item => {
            return item.cat_ids.includes(filteredCategory.id)
        });
    }

    //filtering status
    if (filteredStatus === "Allowed") {
        result = result.filter(item => {
            return item.blocks === 0;
        })
    };

    if (filteredStatus === "Blocked") {
        result = result.filter(item => {
            return item.requests === item.blocks;
        })
    };

    if (filteredStatus === "Mixed") {
        result = result.filter(item => {
            return item.requests !== item.blocks && item.blocks !== 0;
        })
    };

    //map category ids
    const itemCategories = (data: ICategotiesItemStats[]) => {
        let cats: any[] = [];

        Array.from(data).map((cat_ids: any) => {
            return categories.filter((item: ICategotiesItemStats) => {
                if (item.cat !== undefined) {
                    if (item.cat.id === cat_ids) {
                        return cats.push(item.cat.title)
                    }
                }
            })
        });

        return cats.join(', ');
    };

    //pagination
    const itemsCount = 10;
    const {
        firstContentIndex,
        lastContentIndex,
    } = usePagination({contentPerPage: itemsCount, count: result.length});

    return (
        <div className="stats-domains-report">
            <h2 className="stats-domains-report-title">Домены</h2>

            <div className="stats-domains-report-data">
                <div>
                    <ul className="stats-domains-list-header">
                        <li>
                            <h5 className="stats-domains-list-header-title">ДОМЕН</h5>
                        </li>
                        <li className="stats-domains-list-header-filter">
                            <h5 className="stats-domains-list-header-title">КАТЕГОРИЯ</h5>
                        </li>
                        <li className={"stats-domains-list-header-filter"}>
                            <h5 className="stats-domains-list-header-title">ЗАПРОСЫ</h5>
                        </li>
                        <li className="stats-domains-list-header-filter">
                            <h5 className="stats-domains-list-header-title">СТАТУС</h5>
                        </li>
                    </ul>

                    {!error && domainsDataResult.length === 0 ?
                        <div className="stats-domains-list-empty">
                            <p>У вас пока нет статистики</p>
                        </div> :
                        <div className="stats-domains-list">
                            {result.length ? result
                                .slice(firstContentIndex, lastContentIndex)
                                .map((item, index) => (
                                    item.cat_ids &&
                                    <ul
                                        key={index}
                                        className="stats-domains-list-item"
                                        title={item.domain}
                                    >
                                        <li className="stats-domains-list-item-domain">{item.domain}</li>
                                        <li className="stats-domains-list-item-category">{item.cat_ids !== undefined && itemCategories(item.cat_ids) ? item.cat_ids !== undefined && itemCategories(item.cat_ids) : "Внутренняя категория"}</li>
                                        <li>{formatter.format(item.requests)}</li>
                                        <li>
                                            <span className="status-blocked">
                                                Заблокированный
                                            </span>
                                        </li>
                                    </ul>
                                )) :
                                <div className="stats-domains-list-empty">
                                    <p>По вашему запросу нет полезных результатов</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <p className="stats-domains-report-info-title">Подробный отчет о 10 доменах, к которым пользователи чаще всего пытались получить доступ.</p>
        </div>
    )
};

export default PageSix;
