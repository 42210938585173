// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  position: relative;
}
.progress-bar {
  width: 150px;
  height: 10px;
  border-radius: 4px;
  background-color: #F9FAFB;
}
.progress-value {
  position: absolute;
  top: 0;
  width: 20px;
  height: 10px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/progress-bar/ProgressBar.scss","webpack://./src/components/app/variables.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AAMJ;AAJI;EACI,YAAA;EACA,YAAA;EACA,kBAAA;EACA,yBCKS;ADCjB;AAHI;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAKR","sourcesContent":["@import '../../app/variables.scss';\n\n.progress {\n    position: relative;\n\n    &-bar {\n        width: 150px;\n        height: 10px;\n        border-radius: 4px;\n        background-color: $disabled-color;\n    }\n\n    &-value {\n        position: absolute;\n        top: 0;\n        width: 20px;\n        height: 10px;\n        border-radius: 4px;\n    }\n}\n","//colors\n$white: #FFF;\n$black: #000;\n$orange: #F2994A;\n$button-primary-color: #07A9ED;\n$button-secondary-color: #5CCFFF;\n$active-color: #1A75FF;\n$inactive-color: #828282;\n$blue-primary: #0068FF;\n$primary-color: #F6F9FF;\n$secondary-color: #F5F6F8;\n$primary-text-color: #334D6E;\n$border-color: #C2CFE0;\n$border-color2: rgba(28, 102, 246, 0.10);\n$disabled-color: #F9FAFB;\n\n// breakpoints\n$desktop-large: 1440px;\n$desktop: 1200px;\n$tablet-large: 1024px;\n$tablet-small: 768px;\n$mobile: 576px;\n\n:export {\n    desktop: 1200;\n    tabletLarge: 1024;\n    tabletSmall: 768;\n    mobile: 576;\n}\n\n@mixin desktop-large () {\n    @media (max-width: $desktop-large) {\n        @content;\n    }\n}\n\n@mixin desktop() {\n    @media (max-width: $desktop) {\n        @content;\n    }\n}\n\n@mixin tablet-large() {\n    @media (max-width: $tablet-large) {\n        @content;\n    }\n}\n\n@mixin tablet-small() {\n    @media (max-width: $tablet-small) {\n        @content;\n    }\n}\n\n@mixin mobile() {\n    @media (max-width: $mobile) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": `1200`,
	"tabletLarge": `1024`,
	"tabletSmall": `768`,
	"mobile": `576`
};
export default ___CSS_LOADER_EXPORT___;
