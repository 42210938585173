import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Navigation from "../controls/Navigation";
// import DataExportStepsBar from "./DataExportStepsBar";
import DataExportCard from "./DataExportCard";
import './DataExport.scss';

const DataExport: FC = () => {
    const s3IntegrationData = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationReadData);

    return (
       <div className="data-export">
            <div className="controls">
                <Navigation />
            </div>
            {/* <DataExportStepsBar /> */}

            <div className="data-export-list">
                <DataExportCard
                    key="amazon_s3"
                    id="amazon_s3"
                    title="AWS S3"
                    description="S3 - это сервис объектного хранения, который обеспечивает лучшую в отрасли масштабируемость, доступность данных, безопасность и производительность."
                    isCompleted={s3IntegrationData ? true : false}
                    status={s3IntegrationData ? s3IntegrationData.is_valid ? "Подключен" : "Отключен" : ""}
                    lastExport={s3IntegrationData ? s3IntegrationData.last_export : ""}
                />
            </div>
       </div>
    );
};

export default DataExport;
