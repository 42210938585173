import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useActions } from '../../../hooks/useActions';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsCategoriesActionsTypes, ICategotiesItemStats } from "../../../types/statsCategories";
import { ReactGoogleChartEvent, Chart } from "react-google-charts";
import Endpoints from '../../../api/endpoints';
import UpdateStatsButton from '../updateStatsButton';
import ContentBlock from '../../UI/content-block/ContentBlock';
import InfoBlockAccordion from '../../UI/info-block-accordion/InfoBlockAccordion';
import Loader from '../../UI/loader/Loader';
import Tooltip from '../../UI/tooltip/Tooltip';
import FetchError from '../../UI/fetch-error/FetchError';
import CategoryItem from './CategoryItem';
import CategoriesInfo from './CategoriesInfo';
import './Categories.scss';

const Categories: FC = () => {
    const dispatch = useDispatch();
    const { statsCategoriesRequest } = useActions();
    const { STATS } = Endpoints;

    const token = useTypedSelector(state => state.auth.token);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const period = useTypedSelector(state => state.period.period);
    const allProfiles = useTypedSelector(state => state.profiles.allProfilesIds);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const [categoriesDataJson, setCategoriesDataJson] = useState<string>('');
    const [categoriesDataResult, setCategoriesDataResult] = useState<ICategotiesItemStats[]>([]);
    const [lastUpdate, setLastUpdate] = useState<string>('');
    const [cybersecurityCats, setCybersecurityCats] = useState<ICategotiesItemStats[]>([]);
    const [otherCats, setOtherCats] = useState<ICategotiesItemStats[]>([]);
    const [cybersecurityChartData, setCybersecurityChartData] = useState<any[]>([]);
    const [otherChartData, setOtherChartData] = useState<any[]>([]);
    const [lastMonthDataJson, setLastMonthDataJson] = useState<string>('');
    const [lastMonthDataResult, setLastMonthDataResult] = useState<any>([]);
    const [isShowCybersecurityCats, setIsShowCybersecurityCats] = useState<boolean>(true);

    const loading = useTypedSelector(state => state.statsCategories.loading);
    const error = useTypedSelector(state => state.statsCategories.error);

    const formatter = new Intl.NumberFormat('en-US');

    const testCybersecurityCats = [
        {
            "cat": {
                "id": 9,
                "title": "Parked Domains",
                "is_dangerous": true
            },
            "requests": 172868,
            "blocks": 172868
        },
        {
            "cat": {
                "id": 3,
                "title": "Malware",
                "is_dangerous": true
            },
            "requests": 130522,
            "blocks": 130522
        },
        {
            "cat": {
                "id": 12,
                "title": "Botnets & C2C",
                "is_dangerous": true
            },
            "requests": 115736,
            "blocks": 115736
        },
        {
            "cat": {
                "id": 66,
                "title": "Сryptojacking",
                "is_dangerous": true
            },
            "requests": 107683,
            "blocks": 107683
        },
        {
            "cat": {
                "id": 4,
                "title": "Phishing & Typosquatting",
                "is_dangerous": true
            },
            "requests": 18214,
            "blocks": 18214
        },
        {
            "cat": {
                "id": 70,
                "title": "DGA",
                "is_dangerous": true
            },
            "requests": 5423,
            "blocks": 5423
        },
        {
            "cat": {
                "id": 71,
                "title": "Ransomware",
                "is_dangerous": true
            },
            "requests": 2202,
            "blocks": 2202
        }
    ];

    const testOtherCats = [
        {
            "cat": {
                "id": 49,
                "title": "Computers & Internet",
                "is_dangerous": false
            },
            "requests": 17606045,
            "blocks": 518272
        },
        {
            "cat": {
                "id": 29,
                "title": "Social Networks",
                "is_dangerous": false
            },
            "requests": 5554920,
            "blocks": 112688
        },
        {
            "cat": {
                "id": 48,
                "title": "Search Engines",
                "is_dangerous": false
            },
            "requests": 3410894,
            "blocks": 639
        },
        {
            "cat": {
                "id": 63,
                "title": "Trackers & Analytics",
                "is_dangerous": false
            },
            "requests": 3087097,
            "blocks": 154118
        },
        {
            "cat": {
                "id": 34,
                "title": "Corporate Sites",
                "is_dangerous": false
            },
            "requests": 1857552,
            "blocks": 1654
        },
        {
            "cat": {
                "id": 59,
                "title": "Business",
                "is_dangerous": false
            },
            "requests": 1647722,
            "blocks": 4127
        },
        {
            "cat": {
                "id": 100,
                "title": "Internal category",
                "is_dangerous": false
            },
            "requests": 1624020,
            "blocks": 68352
        },
        {
            "cat": {
                "id": 22,
                "title": "Movies & Video",
                "is_dangerous": false
            },
            "requests": 1368440,
            "blocks": 1542
        },
        {
            "cat": {
                "id": 26,
                "title": "Chats & Messengers",
                "is_dangerous": false
            },
            "requests": 1115193,
            "blocks": 117246
        },
        {
            "cat": {
                "id": 36,
                "title": "Education",
                "is_dangerous": false
            },
            "requests": 989644,
            "blocks": 948
        }
    ];

    //get categories stats

    let recursionCount = 0;

    const getCategoriesStats = () => {
        dispatch({
            type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.CATEGORIES}`, requestData, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setCategoriesDataJson(response.data.message);
                setLastUpdate(response.data.updated);

                return dispatch({
                    type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 10) {
                        return dispatch({
                            type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_FAILURE,
                            payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
                        });
                    } else {
                        return getCategoriesStats();
                    }
                }, 1000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_FAILURE,
                payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getCategoriesStats();
        }

        if (profiles.length !== 0) {
            statsCategoriesRequest(getCategoriesStats);
        }
    }, [requestData]);

    //get categories stats to json
    useEffect(() => {
        axios.get(categoriesDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setCategoriesDataResult(Array.from(response.data));

            return dispatch({
                type: StatsCategoriesActionsTypes.STATS_CATEGORIES_DATA_RESULT,
                payload: Array.from(response.data)
            });
        })
        .catch(() => {
            console.log("error");
        })
    }, [categoriesDataJson, error]);

    useEffect(() => {
        dispatch({
            type: StatsCategoriesActionsTypes.STATS_CATEGORIES_DATA,
            payload: categoriesDataResult
        })
    }, [categoriesDataResult]);

    // useEffect(() => {
    //     if (categoriesDataResult.length === 0) {
    //         dispatch({
    //             type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_FAILURE,
    //             payload: 'У вас еще нет статистики'
    //         });
    //     }
    // }, [categoriesDataResult]);

    //get requests to last month
    const getLastMonthStats = () => {
        const somedate = new Date();

        somedate.setMonth(somedate.getMonth() - 1, somedate.getDate());
        const lastMonthEnd = somedate.toISOString();

        somedate.setDate(1);
        const lastMonthStart = somedate.toISOString();

        axios.post(`${process.env.REACT_APP_STATS_URL}/api/rest/v2/get_total_activity/`, {
            ...requestData,
            period: 'range',
            start: lastMonthStart.split('T')[0],
            end: lastMonthEnd.split('T')[0],
            group_by: "day",
        }, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                return setLastMonthDataJson(response.data.message);
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 20) {
                        console.log('time limit');
                    } else {
                        return getLastMonthStats();
                    }
                }, 1000);
            }
        })
        .catch(() => {
            console.log('error');
        });
    };

    useEffect(() => {
        if (requestData.profile_ids.length !== 0) {
            getLastMonthStats();
        }
    }, [categoriesDataResult]);

    useEffect(() => {
        axios.get(lastMonthDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setLastMonthDataResult(response.data);

            return dispatch({
                type: StatsCategoriesActionsTypes.STATS_CATEGORIES_LAST_MONTH_DATA_RESULT,
                payload: response.data
            });
        })
        .catch(() => {
            console.log('error');
        })
    }, [lastMonthDataJson]);

    //chart data create
    useEffect(() => {
        let cybersecurity = categoriesDataResult?.filter((item: ICategotiesItemStats) => {
            if (item.cat !== undefined) {
                return item.cat.is_dangerous
            }
        });

        let other = categoriesDataResult.filter((item: ICategotiesItemStats) => {
            if (item.cat !== undefined) {
                return !item.cat.is_dangerous
            }
        })

        setCybersecurityCats(cybersecurity);
        setOtherCats(other);
    }, [categoriesDataResult, error]);

    interface ISumRequestData {
        blocks: number,
        requests: number,
    };

    const cybersecuritySumRequests = {
        blocks: 0,
        requests: 0,
    };

    const otherSumRequests = {
        blocks: 0,
        requests: 0,
    };

    const [cybersecurityRequestsData, setCybersecurityRequestsData] = useState<ISumRequestData>(cybersecuritySumRequests);
    const [otherRequestsData, setOtherRequestsData] = useState<ISumRequestData>(otherSumRequests);

    const sumData = (data:any[], initial: ISumRequestData) => {
        for (let key in data[0]) {
            //@ts-ignore
            initial[key] = 0;
        };

        data.reduce((acc:any, item:any) => {
            for (let key in item) {
                acc[key] += item[key];
            }
            return acc;
        }, initial);
    };

    useEffect(() => {
        let cybersecurityData: string[][] = [
            ["Category", "Requests"],
        ];
        let otherData: string[][] = [
            ["Category", "Requests"],
        ];

        cybersecurityCats.map((cybersecurity: any, index: number) => {
            cybersecurityData.push([
                cybersecurity.cat.title,
                cybersecurity.requests
            ]);
        });

        otherCats.map((other: any, index: number) => {
            otherData.push([
                other.cat.title,
                other.requests
            ]);
        });

        {error || categoriesDataResult.length === 0 || cybersecurityCats.length === 0 ?
            //sum requests and blocks to cybersecurity cats
            sumData(testCybersecurityCats, cybersecuritySumRequests) :
            sumData(cybersecurityCats, cybersecuritySumRequests)
        }

        {error || categoriesDataResult.length === 0 || otherCats.length === 0 ?
            //sum requests and blocks to other cats
            sumData(testOtherCats, otherSumRequests) :
            sumData(otherCats, otherSumRequests)
        }

        // sumData(cybersecurityCats, cybersecuritySumRequests)
        setCybersecurityRequestsData({
            ...cybersecurityRequestsData,
            requests: cybersecuritySumRequests.requests,
            blocks: cybersecuritySumRequests.blocks,
        });

        // sumData(otherCats, otherSumRequests)
        setOtherRequestsData({
            ...otherRequestsData,
            requests: otherSumRequests.requests,
            blocks: otherSumRequests.blocks,
        });

        {error || categoriesDataResult.length === 0 || cybersecurityCats.length === 0 ?
            setCybersecurityChartData(
                [
                    ["Category", "Requests"],
                    ['Malware', 231142],
                    ['Botnets & C2C', 113345],
                    ['Phishing & Typosquatting', 36532],
                    ['DGA', 765],
                    ['Parked Domains', 12314],
                    ['Сryptojacking', 9213],
                    ['Ransomware', 1232]
                ]
            ) :
            setCybersecurityChartData(cybersecurityData);
        }

        {error || categoriesDataResult.length === 0 ?
            setOtherChartData(
                [
                    ["Category", "Requests"],
                    ['Computers & Internet', 231142],
                    ['Social Networks', 113345],
                    ['Search Engines', 36532],
                    ['Trackers & Analytics', 765],
                    ['Internal category', 12314],
                    ['Movies & Video', 9213],
                    ['Chats & Messengers', 1232],
                    ['Science & Technology', 12314],
                    ['Business', 9213],
                    ['Corporate Sites', 1232]
                ]
            ) :
            setOtherChartData(otherData)
        }
    }, [cybersecurityCats, otherCats]);

    const options = {
        pieHole: 0.7,
        legend: 'none',
        pieSliceText: 'none',
        pieSliceTextStyle: {
            color: '#333',
        },
        chartArea: {
            width:"90%",
            height:"90%",
            left: 5
        },
        tooltip: {
            isHtml: true,
            textStyle: {
                color: '#333',
                fontSize: 14
            },
            showColorCode: true
        },
        backgroundColor: '#FFF'
    };
    const cybersecurityOptions = {
        ...options,
        slices: [
            {
                "id": 0,
                "color": "#2D9CDB"
            },
            {
                "id": 1,
                "color": "#F8961E"
            },
            {
                "id": 2,
                "color": "#F9C74F"
            },
            {
                "id": 3,
                "color": "#90BE6D"
            },
            {
                "id": 4,
                "color": "#F3722C"
            },
            {
                "id": 5,
                "color": "#5E4394"
            },
            {
                "id": 6,
                "color": "#F94144"
            }
        ]
    };
    const otherOptions = {
        ...options,
        slices: [
            {
                "id": 0,
                "color": "#2D9CDB"
            },
            {
                "id": 1,
                "color": "#F8961E"
            },
            {
                "id": 2,
                "color": "#F9C74F"
            },
            {
                "id": 3,
                "color": "#90BE6D"
            },
            {
                "id": 4,
                "color": "#F3722C"
            },
            {
                "id": 5,
                "color": "#015FCC"
            },
            {
                "id": 6,
                "color": "#4E4D4D"
            },
            {
                "id": 7,
                "color": "#C0D000"
            },
            {
                "id": 8,
                "color": "#009688"
            },
            {
                "id": 9,
                "color": "#5E4394"
            },
            {
                "id": 10,
                "color": "#219653"
            },
            {
                "id": 11,
                "color": "#BB6BD9"
            },
            {
                "id": 12,
                "color": "#BDBDBD"
            },
            {
                "id": 13,
                "color": "#706DFF"
            },
            {
                "id": 14,
                "color": "#6DB0FF"
            },
            {
                "id": 15,
                "color": "#F94144"
            },
            {
                "id": 16,
                "color": "#1C66F6"
            },
            {
                "id": 17,
                "color": "#EF676C"
            },
            {
                "id": 18,
                "color": "#008B94"
            },
            {
                "id": 19,
                "color": "#CDA7F0"
            },
            {
                "id": 20,
                "color": "#87F7D1"
            },
            {
                "id": 21,
                "color": "#7DAB63"
            },
            {
                "id": 22,
                "color": "#86BE55"
            },
            {
                "id": 23,
                "color": "#109968"
            },
            {
                "id": 24,
                "color": "#298AAF"
            },
            {
                "id": 25,
                "color": "#C86555"
            },
            {
                "id": 26,
                "color": "#9488E9"
            },
            {
                "id": 27,
                "color": "#BA28AC"
            },
            {
                "id": 28,
                "color": "#0867C3"
            },
            {
                "id": 29,
                "color": "#14168C"
            },
            {
                "id": 30,
                "color": "#4ACD7F"
            },
            {
                "id": 31,
                "color": "#219AE7"
            },
            {
                "id": 32,
                "color": "#F0BDB2"
            },
            {
                "id": 33,
                "color": "#22890F"
            },
            {
                "id": 34,
                "color": "#0D5DAD"
            },
            {
                "id": 35,
                "color": "#C67273"
            },
            {
                "id": 36,
                "color": "#98DC0C"
            },
            {
                "id": 37,
                "color": "#284B1B"
            },
            {
                "id": 38,
                "color": "#D3D08A"
            },
            {
                "id": 39,
                "color": "#C5DBDF"
            },
            {
                "id": 40,
                "color": "#B86DAC"
            },
            {
                "id": 41,
                "color": "#98B2C1"
            },
            {
                "id": 42,
                "color": "#E1732D"
            },
            {
                "id": 43,
                "color": "#869104"
            },
            {
                "id": 44,
                "color": "#D4D609"
            },
            {
                "id": 45,
                "color": "#C96426"
            },
            {
                "id": 46,
                "color": "#6DE48C"
            },
            {
                "id": 47,
                "color": "#AF2B1E"
            },
            {
                "id": 48,
                "color": "#14B91F"
            },
            {
                "id": 49,
                "color": "#AA91A4"
            },
            {
                "id": 50,
                "color": "#65D7E4"
            },
            {
                "id": 51,
                "color": "#7E8690"
            },
            {
                "id": 52,
                "color": "#928E44"
            },
            {
                "id": 53,
                "color": "#42119D"
            },
            {
                "id": 54,
                "color": "#535895"
            },
            {
                "id": 55,
                "color": "#8A8C7D"
            },
            {
                "id": 56,
                "color": "#602B66"
            },
            {
                "id": 57,
                "color": "#4F2709"
            }
        ]
    };

    const chartEvents: ReactGoogleChartEvent[] = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();

                if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    const { row, column } = selectedItem;

                    if (row != null && column != null) {
                        console.log(dataTable?.getValue(row, column));
                    } else if (row != null) {
                        console.log(dataTable?.getValue(row, 0));
                    } else if (column != null) {
                        console.log(dataTable?.getValue(0, column));
                    }
                }
            },
        },
    ];

    const {
        requests: cyb_requests,
        blocks: cyb_blocks
    } = cybersecurityRequestsData;
    const {
        requests: oth_requests,
        blocks: oth_blocks
    } = otherRequestsData;
    const {
        dangerous_blocks: d_blocks
    } = lastMonthDataResult;
    const cybRequestsRatio = cyb_requests - d_blocks;

    return (
        <ContentBlock
            title={"Категории"}
            padding="0"
            content={
                <>
                    <div className="stats-categories">
                        <div className="stats-categories-controls">
                            <label
                                htmlFor="cybersecurity_cats_button"
                                className={`stats-categories-controls-button${isShowCybersecurityCats ? '-active' : ''}`}
                            >
                                <input
                                    type="radio"
                                    id="cybersecurity_cats_button"
                                    name="cats_selector"
                                    onChange={()=> setIsShowCybersecurityCats(!isShowCybersecurityCats)}
                                    defaultChecked
                                    hidden
                                />
                                Безопасность
                            </label>
                            <label
                                htmlFor="other_cats_buton"
                                className={`stats-categories-controls-button${!isShowCybersecurityCats ? '-active' : ''}`}
                            >
                                <input
                                    type="radio"
                                    id="other_cats_buton"
                                    name="cats_selector"
                                    onChange={()=> setIsShowCybersecurityCats(!isShowCybersecurityCats)}
                                    hidden
                                />
                                Остальные категории
                                <Tooltip
                                    text="Количество запросов в разбивке по категориям может отличаться от количества в других разделах, поскольку некоторые домены могут относиться к нескольким категориям одновременно. Здесь мы подсчитываем количество запросов по отдельным категориям, что может способствовать увеличению общего числа."
                                    width="234px"
                                />
                            </label>
                        </div>

                        {isShowCybersecurityCats ?
                            <div className="stats-categories-cybersecurity">
                                <div
                                    className="stats-categories-cybersecurity-data"
                                    style={{
                                        opacity: loading || error || categoriesDataResult.length === 0 || cybersecurityCats.length === 0 ? 0.2 : 1,
                                        pointerEvents: loading || error || categoriesDataResult.length === 0 || cybersecurityCats.length === 0 ? 'none' : 'auto'
                                    }}
                                >
                                    <div className="stats-categories-cybersecurity-data-requests">
                                        <span className="stats-categories-cybersecurity-data-requests-title">
                                            Всего вредоносных запросов
                                        </span>
                                        <div className="stats-categories-cybersecurity-data-requests-count">
                                            <p>{formatter.format(cyb_requests)}</p>
                                            <div
                                                className="stats-categories-cybersecurity-data-requests-count-last-month"
                                                style={{ color: Math.sign(cybRequestsRatio) ? '#EB5757' : '#219653' }}
                                            >
                                                <span className={`stats-categories-cybersecurity-data-requests-count${Math.sign(cybRequestsRatio) ? '-up' : '-down'}`} />
                                                <span>{`${formatter.format(cybRequestsRatio)} (${d_blocks ? Math.round((cybRequestsRatio) * 100 / d_blocks) : '-'}%)`}</span>
                                                <span style={{ color: '#4F4F4F' }}>vs last month</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stats-categories-cybersecurity-data-blocked">
                                        <span className="stats-categories-cybersecurity-data-blocked-title">
                                            Всего угроз предотвращено
                                        </span>
                                        <div className="stats-categories-cybersecurity-data-blocked-count">
                                            <p>{formatter.format(cyb_blocks)}</p>
                                            <span>{`${Math.round(cyb_blocks * 100 / cyb_requests)}%`}</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="stats-categories-cybersecurity-content"
                                    style={{
                                        opacity: loading || error || categoriesDataResult.length === 0 || cybersecurityCats.length === 0 ? 0.2 : 1,
                                        pointerEvents: loading || error || categoriesDataResult.length === 0 || cybersecurityCats.length === 0 ? 'none' : 'auto'
                                    }}
                                >
                                    <div className="stats-categories-cybersecurity-list">
                                        {error || categoriesDataResult.length === 0 || cybersecurityCats.length === 0 ?
                                            testCybersecurityCats.map((cybersecurity, index:any) => (
                                                <CategoryItem
                                                    key={cybersecurity.cat.id}
                                                    title={cybersecurity.cat.title}
                                                    requests={cybersecurity.requests}
                                                    blocks={cybersecurity.blocks}
                                                    colorIndex={index}
                                                    colors={cybersecurityOptions.slices}
                                                />
                                            )) :
                                            cybersecurityCats.map((cybersecurity, index:any) => (
                                                <CategoryItem
                                                    key={cybersecurity.cat.id}
                                                    title={cybersecurity.cat.title}
                                                    requests={cybersecurity.requests}
                                                    blocks={cybersecurity.blocks}
                                                    colorIndex={index}
                                                    colors={cybersecurityOptions.slices}
                                                />
                                            ))
                                        }
                                    </div>
                                    <div className="stats-categories-cybersecurity-chart">
                                        <Chart
                                            chartType="PieChart"
                                            width="290px"
                                            height="290px"
                                            data={cybersecurityChartData}
                                            options={cybersecurityOptions}
                                            chartEvents={chartEvents}
                                        />
                                        <span className="stats-categories-cybersecurity-chart-total">100%</span>
                                    </div>
                                </div>

                                {loading &&
                                    <Loader
                                        text='Пожалуйста, подождите...'
                                    />
                                }

                                {error &&
                                    <FetchError
                                        text={error}
                                        isButton={true}
                                        isButtonDisabled={loading ? true : false}
                                        isIcon={false}
                                        clickHeandler={getCategoriesStats}
                                    />
                                }

                                {!error && categoriesDataResult.length === 0 &&
                                    <FetchError
                                        text={loading ? "" : "У вас еще нет статистики"}
                                        isButton={true}
                                        isButtonDisabled={loading ? true : false}
                                        isIcon={false}
                                        clickHeandler={getCategoriesStats}
                                    />
                                }

                                {categoriesDataResult.length !== 0 && cybersecurityCats.length === 0 &&
                                    <FetchError
                                        text={loading ? "" : "Никаких запросов к категориям кибербезопасности не было, поэтому у вас пока нет статистики кибербезопасности."}
                                        isButton={true}
                                        isButtonDisabled={loading ? true : false}
                                        isIcon={false}
                                        clickHeandler={getCategoriesStats}
                                    />
                                }
                            </div> :
                            <div
                                className="stats-categories-other">
                                <div
                                    className="stats-categories-other-data"
                                    style={{
                                        opacity: loading || error || categoriesDataResult.length === 0 || otherCats.length === 0 ? 0.2 : 1,
                                        pointerEvents: loading || error || categoriesDataResult.length === 0 || otherCats.length === 0 ? 'none' : 'auto'
                                    }}
                                >
                                    <div className="stats-categories-other-data-requests">
                                        <span className="stats-categories-other-data-requests-title">
                                            Всего нефильтрованных запросов
                                        </span>
                                        <div className="stats-categories-other-data-requests-count">
                                            <p>{formatter.format(oth_requests)}</p>
                                        </div>
                                    </div>
                                    <div className="stats-categories-other-data-blocked">
                                        <span className="stats-categories-other-data-blocked-title">
                                        Всего заблокированных запросов
                                        </span>
                                        <div className="stats-categories-other-data-blocked-count">
                                            <p>{formatter.format(oth_blocks)}</p>
                                            <span>{`${Math.round(oth_blocks * 100 / oth_requests)} %`}</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="stats-categories-other-content"
                                    style={{
                                        opacity: loading || error || categoriesDataResult.length === 0 || otherCats.length === 0 ? 0.2 : 1,
                                        pointerEvents: loading || error || categoriesDataResult.length === 0 || otherCats.length === 0 ? 'none' : 'auto'
                                    }}
                                >
                                    <div className="stats-categories-other-list">
                                        {error || categoriesDataResult.length === 0 || otherCats.length === 0 ?
                                            testOtherCats.map((other, index: any) => (
                                                <CategoryItem
                                                    key={other.cat.id}
                                                    title={other.cat.title}
                                                    requests={other.requests}
                                                    blocks={other.blocks}
                                                    colorIndex={index}
                                                    colors={otherOptions.slices}
                                                />
                                            )) :
                                            otherCats.map((other, index:any) => (
                                                <CategoryItem
                                                    key={other.cat.id}
                                                    title={other.cat.title}
                                                    requests={other.requests}
                                                    blocks={other.blocks}
                                                    colorIndex={index}
                                                    colors={otherOptions.slices}
                                                />
                                            ))
                                        }
                                    </div>
                                    <div className="stats-categories-other-chart">
                                        <Chart
                                            chartType="PieChart"
                                            width="290px"
                                            height="290px"
                                            data={otherChartData}
                                            options={otherOptions}
                                            chartEvents={chartEvents}
                                        />
                                        <span className="stats-categories-other-chart-total">100%</span>
                                    </div>
                                </div>

                                {loading &&
                                    <Loader
                                        text='Пожалуйста, подождите...'
                                    />
                                }

                                {error &&
                                    <FetchError
                                        text={error}
                                        isButton={true}
                                        isButtonDisabled={loading ? true : false}
                                        isIcon={false}
                                        clickHeandler={getCategoriesStats}
                                    />
                                }

                                {!error && categoriesDataResult.length === 0 &&
                                    <FetchError
                                        text={loading ? "" : "У вас еще нет статистики"}
                                        isButton={true}
                                        isButtonDisabled={loading ? true : false}
                                        isIcon={false}
                                        clickHeandler={getCategoriesStats}
                                    />
                                }
                            </div>
                        }

                        <div className="stats-categories-last-update">
                            <UpdateStatsButton
                                updateStats={getCategoriesStats}
                                lastUpdate={lastUpdate}
                                disabledTimeout={5000}
                            />
                        </div>
                    </div>
                    <InfoBlockAccordion
                        id="category_breakdown"
                        title='Разбивка категорий кибербезопасности по количеству запросов, предотвращенным угрозам и проценту каждой категории в общем объеме.'
                        padding="0 24px"
                        is_content={true}
                        content={<CategoriesInfo />}
                    />
                </>
            }
        />
    )
};

export default Categories;
