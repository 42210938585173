import axios from "axios";
import { Dispatch } from "redux";
import { ICategoriesResponse, CategoriesActions, CategoriesActionsTypes } from "../../types/categories";

export const fetchCategories = () => {
    return function (dispatch: Dispatch<CategoriesActions>) {
        dispatch({
            type: CategoriesActionsTypes.GET_CATEGORIES_LIST
        });

        axios.get<ICategoriesResponse>(`${process.env.REACT_APP_BASE_URL}/cabinet/rest_api/v1/categories/?page_size=100`,
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: CategoriesActionsTypes.GET_CATEGORIES_LIST_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: CategoriesActionsTypes.GET_CATEGORIES_LIST_FAILURE,
                payload: 'Oops!! Something went wrong...'
            });
        })
    };
};
