import React, { FC } from "react";

const CategoriesInfo: FC = () => {
    return (
        <div className="stats-categories-info">
            <h5 className="stats-categories-info-title">Если вы заметили несоответствие между количеством блоков и запросов, это может быть связано с:</h5>
            <div className="stats-categories-info-text">
                <p className="stats-categories-info-text-item">Домены из этой категории были разблокированы в настройках вашего личного кабинета на часть выбранного периода.</p>
                <p className="stats-categories-info-text-item">Ранее домен относился к другой категории или не имел категории, но теперь демонстрирует признаки угрозы или контента, заблокированного настройками личной учетной записи.</p>
                <p className="stats-categories-info-text-item">Несанкционированный доступ к вашей личной учетной записи или сети со стороны внутреннего или внешнего злоумышленника.</p>
                <p className="stats-categories-info-text-item">Администратор разрешил эту категорию в ваших настройках.</p>
            </div>
            <p>Если вы подозреваете, что несоответствие не связано с вашими действиями, проверьте журналы своей учетной записи или сообщите нам, что вы подверглись сетевой атаке.</p>
        </div>
    )
};

export default CategoriesInfo;
