import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsDetailedActionsTypes, IDetailedItem } from "../../../types/statsDetailed";
import { renderDeviceName } from '../devices/DeviceItemName';
import Endpoints from '../../../api/endpoints';
import usePagination from '../../../hooks/usePagination';
import useSortable from '../../../hooks/useSortable';
import Controls from '../../controls/Controls';
import DetailedItem from './DetailedItem';
import DomainInfo from './DomainInfo';
import Search from '../../UI/search/Search';
import ContentBlock from '../../UI/content-block/ContentBlock';
import Paginator from '../../UI/paginator/Paginator';
import Tooltip from '../../UI/tooltip/Tooltip';
import Loader from '../../UI/loader/Loader';
import FetchError from '../../UI/fetch-error/FetchError';
import PickedFilter from '../../UI/picked-filter/PickedFilter';
import './Detailed.scss';

const Detailed: FC = () => {
    const dispatch = useDispatch();
    const { statsDetailedRequest } = useActions();
    const { STATS } = Endpoints;

    const token = useTypedSelector(state => state.auth.token);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const period = useTypedSelector(state => state.period.period);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const categoriesList = useTypedSelector(state => state.categories.categories);
    const deviceList = useTypedSelector(state => state.statsDevices.deviceList);

    const [detailedDataJson, setDetailedDataJson] = useState<string>("");
    const [detailedDataResult, setDetailedDataResult] = useState<IDetailedItem[]>([]);
    const [filteredStatus, setFilteredStatus] = useState<string>("Все");
    const [filteredCategory, setFilteredCategory] = useState<{id: number|null, title: string}>({
        id: NaN,
        title: "Все"
    });

    const [domainsFilter, setDomainsFilter] = useState<string>("");
    const [isShowDomainsFilter, setIsShowDomainsFilter] = useState<boolean>(false);

    const [searchDetailed, setSearchDetailed] = useState<string>("");
    const [searchCategory, setSearchCategory] = useState<string>("");
    const [isShowFilteringStatusMenu, setIsShowFilteringStatusMenu] = useState<boolean>(false);
    const [isShowFilteringCategoryMenu, setIsShowFilteringCategoryMenu] = useState<boolean>(false);

    const loading = useTypedSelector(state => state.statsDetailed.loading);
    const error = useTypedSelector(state => state.statsDetailed.error);

    const detailedStatus = [
        {
            name: 'Все',
        },
        {
            name: 'Разрешенный',
        },
        {
            name: 'Заблокированный',
        }
    ];

    //get detailed stats

    let recursionCount = 0;

    const getDetailedStats = () => {
        dispatch({
            type: StatsDetailedActionsTypes.GET_STATS_DETAILED
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.DETAILED}`, requestData, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setDetailedDataJson(response.data.message);

                return dispatch({
                    type: StatsDetailedActionsTypes.GET_STATS_DETAILED_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 10) {
                        return dispatch({
                            type: StatsDetailedActionsTypes.GET_STATS_DETAILED_FAILURE,
                            payload: 'Упс! Что-то пошло не так... Повторите попытку позже..'
                        });
                    } else {
                        return getDetailedStats();
                    }
                }, 3000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsDetailedActionsTypes.GET_STATS_DETAILED_FAILURE,
                payload: 'Упс! Что-то пошло не так... Повторите попытку позже..'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getDetailedStats();
        }

        statsDetailedRequest(getDetailedStats);
    }, [requestData]);

    //get stats to json
    useEffect(() => {
        axios.get(detailedDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setDetailedDataResult(response.data);
            dispatch({
                type: StatsDetailedActionsTypes.STATS_DETAILED_DATA,
                payload: Array.from(response.data).slice(0, 10)
            })
        })
        .catch(() => {
            console.log('error');
        })
    }, [detailedDataJson]);

    //sorted detailed to requests
    let { result, requestSort, sortConfig } = useSortable(detailedDataResult);
    let catsList = categoriesList.results;

    // const sortedDirectionClass = (name: string) => {
    //     if (!sortConfig) {
    //         return;
    //     }
    //     return sortConfig.key === name ? sortConfig.direction : undefined;
    // };

    //filtering detailed
    if (searchDetailed.length > 0) {
        result = result.filter(item => {
            return item.domain.toLocaleLowerCase().match(searchDetailed)
        });
    };

    //filtering categories
    if (searchCategory.length > 0) {
        catsList = catsList.filter(item => {
            return item.title.toLocaleLowerCase().match(searchCategory)
        })
    };

    if (filteredCategory.title !== "Все") {
        result = result.filter(item => {
            return item.cat_ids.includes(filteredCategory.id)
        });
    }

    //filtering status
    if (filteredStatus === "Разрешенный") {
        result = result.filter(item => {
            return item.blocks === 0;
        })
    };

    if (filteredStatus === "Заблокированный") {
        result = result.filter(item => {
            return item.requests === item.blocks;
        })
    };

    //filtering domains to multiple select
    let domaisFilterList: any[] = [];
    let domaisFilterChecked: any[] = [];
    let domainsSet = new Set();

    if (domainsFilter.length > 0) {
        for (const iterator of result) {
            if (iterator.domain.toLocaleLowerCase().match(domainsFilter)) {
                domaisFilterList = Array.from(domainsSet.add(iterator.domain))
            }
        }
    };

    const searchDetailedHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchDetailed(e.target.value)
    };

    const searchCategoryHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchCategory(e.target.value)
    };

    const categoryFilteringHandler = (category: { id: number|null, title: string }): void => {
        setFilteredCategory(category);
        setIsShowFilteringCategoryMenu(!isShowFilteringCategoryMenu);
    };

    const statusFilteringHandler = (status: string): void => {
        setFilteredStatus(status);
        setIsShowFilteringStatusMenu(!isShowFilteringStatusMenu);
    };

    //filtering by multiple domains

    // const showHideFilterDomainsMenu = (): void => {
    //     setIsShowDomainsFilter(!isShowDomainsFilter);
    // };

    // const domainsFilteringHandler = (e: React.ChangeEvent<HTMLInputElement>):void => {
    //     setDomainsFilter(e.target.value);
    // };

    // const domainsCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.checked) {
    //         domaisFilterChecked.push(e.target.value);
    //     } else {
    //         let index = domaisFilterChecked.indexOf(e.target.value);

    //         if (index !== -1) {
    //             domaisFilterChecked.splice(index, 1)
    //         }
    //     }
    //     // let keysSelectedFilter = domaisFilterChecked.map(s => s);
    //     // let res = result.filter(i =>
    //     //     i.domain.some((k) =>
    //     //       keysSelectedFilter.includes(k)
    //     //     )
    //     //   )

    //     console.log(domaisFilterChecked);
    // }; //TODO

    //reset all filters
    const onResetFilters = () => {
        setFilteredStatus("Все");
        setFilteredCategory({
            id: NaN,
            title: "Все"
        });
    };

    //map category ids
    const itemCategories = (data: any[]) => {
        let cats: any[] = [];

        data && data.map((cat_ids) => {
            return categoriesList.results.filter((item) => {
                if (item && item.id === cat_ids) {
                    return cats.push(item.title)
                }
            })
        });

        return cats.join(', ');
    };

    //pagination
    const itemsCount = 10;
    const {
        firstContentIndex,
        lastContentIndex,
        nextPage,
        prevPage,
        page,
        gaps,
        setPage,
        totalPages,
    } = usePagination({contentPerPage: itemsCount, count: result.length});

    return (
        <div className="stats">
            <Controls />
            <ContentBlock
                title={"Логи"}
                padding="0"
                content={
                    <div className="stats-detailed">
                        <div
                            style={{
                                opacity: loading || error ? 0.2 : 1,
                                pointerEvents: loading || error ? 'none' : 'auto'
                            }}
                        >
                            <div className="stats-detailed-controls">
                                <div className="stats-detailed-controls-filters">
                                    <Search
                                        value={searchDetailed}
                                        placeholder="Найти домен"
                                        margin="0"
                                        width="325px"
                                        searchHandler={searchDetailedHandler}
                                    />
                                    <div className="stats-detailed-controls-filters-wrapper">
                                        {/* filtering by multiple domains */}

                                        {/* <div className="stats-detailed-controls-filters-filter">
                                            <button
                                                type="button"
                                                className="stats-detailed-controls-filters-filter-open"
                                                onClick={showHideFilterDomainsMenu}
                                            >
                                                Domain
                                            </button>

                                            {isShowDomainsFilter &&
                                                <div className="stats-detailed-controls-filters-filter-menu">
                                                    <Search
                                                        value={domainsFilter}
                                                        placeholder="Search"
                                                        margin="0"
                                                        padding="12px 16px"
                                                        width="164px"
                                                        searchHandler={(e: ChangeEvent<HTMLInputElement>) => domainsFilteringHandler(e)}
                                                    />

                                                    {domaisFilterList.map((item, index) => (
                                                        <label key={index} className="stats-detailed-controls-filters-filter-menu-item">
                                                            <input
                                                                value={item}
                                                                type="checkbox"
                                                                onChange={(e)=> domainsCheckedHandler(e)}
                                                            />
                                                            <span>{item}</span>
                                                        </label>
                                                    ))}
                                                </div>
                                            }
                                        </div> */}

                                        {/* filtering categories */}
                                        <div className="stats-detailed-controls-filters-filter">
                                            <button
                                                type="button"
                                                className="stats-detailed-controls-filters-filter-open"
                                                onClick={()=> setIsShowFilteringCategoryMenu(!isShowFilteringCategoryMenu)}
                                            >
                                                Категории
                                            </button>

                                            {isShowFilteringCategoryMenu &&
                                                <div className="stats-detailed-controls-filters-filter-menu">
                                                    <Search
                                                        value={searchCategory}
                                                        placeholder="Поиск"
                                                        margin="0"
                                                        padding="12px 16px"
                                                        width="164px"
                                                        searchHandler={(e: ChangeEvent<HTMLInputElement>) => searchCategoryHandler(e)}
                                                    />

                                                    <ul
                                                        className="stats-detailed-controls-filters-filter-menu-item"
                                                        onClick={()=> categoryFilteringHandler({
                                                            id: NaN,
                                                            title: "Все"
                                                        })}
                                                        role="button"
                                                    >
                                                        <li>Все</li>
                                                    </ul>

                                                    {catsList.map((item, index) => (
                                                        <ul
                                                            key={index}
                                                            className="stats-detailed-controls-filters-filter-menu-item"
                                                            onClick={()=> categoryFilteringHandler(item)}
                                                            role="button"
                                                        >
                                                            <li>{item.title}</li>
                                                        </ul>
                                                    ))}
                                                </div>
                                            }
                                        </div>

                                        {/* filtering status */}
                                        <div className="stats-detailed-controls-filters-filter">
                                            <button
                                                type="button"
                                                className="stats-detailed-controls-filters-filter-open"
                                                onClick={()=> setIsShowFilteringStatusMenu(!isShowFilteringStatusMenu)}
                                            >
                                                Статус
                                            </button>

                                            {isShowFilteringStatusMenu &&
                                                <div className="stats-detailed-controls-filters-filter-menu">
                                                    {detailedStatus.map((item, index) => (
                                                        <ul
                                                            key={index}
                                                            className="stats-detailed-controls-filters-filter-menu-item"
                                                            onClick={()=> statusFilteringHandler(item.name)}
                                                            role="button"
                                                        >
                                                            <li>{item.name}</li>
                                                        </ul>
                                                    ))}
                                                </div>
                                            }
                                        </div>

                                        <button
                                            type="button"
                                            className="stats-detailed-controls-filters-reset"
                                            onClick={onResetFilters}
                                            >
                                            Сбросить все фильтры
                                        </button>
                                    </div>
                                </div>
                                <div className="stats-detailed-controls-picked">
                                    {filteredCategory.title !== "Все" &&
                                        <PickedFilter
                                            title={"Категории"}
                                            picked={filteredCategory.title}
                                            margin="0"
                                            clickHandler={()=> setFilteredCategory({
                                                id: NaN,
                                                title: "Все"
                                            })}
                                        />
                                    }

                                    {filteredStatus !== "Все" &&
                                        <PickedFilter
                                            width="166"
                                            title={"Статус"}
                                            picked={filteredStatus}
                                            margin="0"
                                            clickHandler={()=> setFilteredStatus("Все")}
                                        />
                                    }
                                </div>
                            </div>

                            <ul className="stats-detailed-list-header">
                                {/* Whois data add todo */}
                                {/* <li></li> */}
                                <li>ВРЕМЯ</li>
                                <li>ПОЛЬЗОВАТЕЛИ / АГЕНТЫ / IP</li>
                                <li>ДОМЕНЫ</li>
                                <li>КАТЕГОРИИ</li>
                                <li>СТАТУС</li>
                            </ul>

                            {!error && detailedDataResult.length === 0 ?
                                <div className="stats-detailed-list-empty">
                                    <p>У вас еще нет статистики</p>
                                </div> :
                                <div className="stats-detailed-list">
                                    {result.length ? result
                                        .slice(firstContentIndex, lastContentIndex)
                                        .map((item, index) => (
                                            item.cat_ids &&
                                            <DetailedItem
                                                key={index}
                                                domain={item.domain}
                                                category={itemCategories(item.cat_ids)}
                                                time={item.timestamp}
                                                user={renderDeviceName(deviceList, item)}
                                                requests={item.requests}
                                                blocks={item.blocks}
                                                sortChange={sortConfig}
                                            />
                                        )) :
                                        <div className="stats-detailed-list-empty">
                                            <p>По вашему запросу нет полезных результатов</p>
                                        </div>
                                    }

                                    {result.length > itemsCount &&
                                        <div className="stats-detailed-list-pagination">
                                            <div className="stats-detailed-list-pagination-counts">
                                                <span>Showing</span>{`${firstContentIndex + 1}-${firstContentIndex + itemsCount}`}<span>of</span>{result.length}
                                                <Tooltip
                                                    text="Расширенная статистика представляет 1000 последних событий из вашей учетной записи за выбранный период. <br/> Следовательно, ежедневная и ежемесячная статистика может отображать идентичные данные за один и тот же месяц, <br/> ограничиваясь последними 1000 событиями. Для получения полного обзора воспользуйтесь нашим инструментом экспорта статистики <br/>, чтобы получить доступ ко всем данным за указанный вами период времени."
                                                />
                                            </div>

                                            <Paginator
                                                nextPage={nextPage}
                                                prevPage={prevPage}
                                                page={page}
                                                gaps={gaps}
                                                setPage={setPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        {loading &&
                            <Loader
                                text='Пожалуйста, подождите...'
                            />
                        }

                        {error &&
                            <FetchError
                                text={error}
                                isButton={true}
                                isButtonDisabled={loading ? true : false}
                                isIcon={false}
                                clickHeandler={getDetailedStats}
                            />
                        }
                    </div>
                }
            />
            <DomainInfo />
        </div>
    )
};

export default Detailed;
