import { FC, useState, useEffect, useContext } from "react";
import { StatsDetailedActionsTypes, IDomainInfo } from "../../../types/statsDetailed";
import { useDispatch } from "react-redux";
// import SlidingMenuContext from "../../../contexts/slidingMenuContext";
import './Detailed.scss';

const DetailedItem: FC<any> = ({
        domain,
        category,
        time,
        user,
        requests,
        blocks,
    }) => {
    const dispatch = useDispatch();

    //Whois data add todo
    // const { isShowSlidingMenu, setIsShowSlidingMenu } = useContext(SlidingMenuContext);

    const [status, setStatus] = useState<string>('');
    const [domainInfo, setDomainInfo] = useState<IDomainInfo>({
        domain: domain,
        status: ""
    });

    useEffect(() => {
        if (blocks === requests) {
            setStatus('Заблокированный')
            setDomainInfo({...domainInfo, status: 'Заблокированный'})
        }

        if (blocks === 0) {
            setStatus('Разрешенный')
            setDomainInfo({...domainInfo, status: 'Разрешенный'})
        }
    }, [requests, blocks]);

    //get domin info

    const getDomainInfo = () => {
        // setIsShowSlidingMenu(!isShowSlidingMenu); //Whois data add todo
        setDomainInfo({...domainInfo, domain: domain})
    };

    useEffect(() => {
        dispatch({
            type: StatsDetailedActionsTypes.GET_STATS_DETAILED_INFO_SUCCESS,
            payload: domainInfo
        })
    }, [domainInfo])

    return (
        <div
            title={domain}
            className="stats-detailed-list"
            onClick={getDomainInfo}
        >
            <ul className="stats-detailed-list-item">
                {/* Whois data add todo */}
                {/* <button
                    type="button"
                    value={domain}
                    className="stats-detailed-list-item-close"
                /> */}
                <li>{time}</li>
                <li>{user}</li>
                <li className="stats-detailed-list-item-detailed">{domain}</li>
                <li className="stats-detailed-list-item-category">{category ? category : "Внутренняя категория"}</li>
                <li>
                    <span className={`status-${status.toLocaleLowerCase()}`}>
                        {status}
                    </span>
                </li>
            </ul>
        </div>
    )
};

export default DetailedItem;
